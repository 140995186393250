import React from "react"
import { Stack, Main } from "@layout"
import PageTitle from "../components/Shared/PageTitle"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import "./animation.css"

export default function () {

  const subheader = `Coming soon.`

  return (
    <>
      <Seo title="Secure Payments" />
      <Divider space={3} />
      <div className="animateInDown">
        <PageTitle header="Secure Payments" subheader={subheader} />
      </div>
    </>
  )
}
